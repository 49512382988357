import { ReactNode, createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface LSContextModel {
  lastSectionId: string;
  updateLastSectionId: (sectionId: string) => void;
  getLastSectionOpenned: () => string | null;
  removeLastSectionOpenned: () => void;
  //
  lastLayer: string;
  updateLastLayer: (lastLayer: string) => void;
  getLastLayerChecked: () => string | null;
  removeLastLayerChecked: () => void;
  //
  lastRoundId: string;
  updateLastRoundId: (lastLayer: string) => void;
  getLastRoundId: () => string | null;
  removeLastRoundId: () => void;
}

const LAST_SECTION_ID = 'last-section-id';
const LAST_LAYER = 'last-layer';
const LAST_ROUND_ID = 'last-round-id';

interface LSContextProviderProps {
  children: ReactNode;
}

export const LSContext = createContext<LSContextModel | undefined>(undefined);

export const LSProvider: React.FC<LSContextProviderProps> = ({ children }) => {
  const [lastSectionId, setLastSectionId] = useState(localStorage.getItem(LAST_SECTION_ID) ?? '');
  const [lastLayer, setLastLayer] = useState(localStorage.getItem(LAST_LAYER) ?? '');
  const [lastRoundId, setLastRoundId] = useState(localStorage.getItem(LAST_ROUND_ID) ?? '');

  const location = useLocation();
  const inStowageDetailsView = location.pathname.includes('/stowagePlan/');

  ////////////////////////////////

  const getLastSectionOpenned = () => localStorage.getItem(LAST_SECTION_ID);

  const setLastSectionOpenned = (value: string) => localStorage.setItem(LAST_SECTION_ID, value);

  const removeLastSectionOpenned = () => {
    localStorage.removeItem(LAST_SECTION_ID);
  };

  const updateLastSectionId = (sectionId: string) => {
    setLastSectionId(sectionId);
  };

  ////////////////////////////////

  const getLastLayerChecked = () => localStorage.getItem(LAST_LAYER);

  const setLastLayerChecked = (value: string) => localStorage.setItem(LAST_LAYER, value);

  const removeLastLayerChecked = () => {
    localStorage.removeItem(LAST_LAYER);
  };

  const updateLastLayer = (lastLayer: string) => {
    setLastLayer(lastLayer);
  };

  ////////////////////////////////

  const getLastRoundId = () => localStorage.getItem(LAST_ROUND_ID);

  const setRoundId = (value: string) => localStorage.setItem(LAST_ROUND_ID, value);

  const removeLastRoundId = () => {
    localStorage.removeItem(LAST_ROUND_ID);
  };

  const updateLastRoundId = (roundId: string) => {
    setLastRoundId(roundId);
  };

  const contextData: LSContextModel = {
    lastSectionId,
    updateLastSectionId,
    getLastSectionOpenned,
    removeLastSectionOpenned,
    lastLayer,
    updateLastLayer,
    getLastLayerChecked,
    removeLastLayerChecked,
    lastRoundId,
    updateLastRoundId,
    getLastRoundId,
    removeLastRoundId
  };

  useEffect(() => {
    const localValue = getLastSectionOpenned();
    if (localValue !== lastSectionId) setLastSectionOpenned(lastSectionId);
  }, [lastSectionId]);

  useEffect(() => {
    const localValue = getLastLayerChecked();
    if (localValue != lastLayer) setLastLayerChecked(lastLayer);
  }, [lastLayer]);

  useEffect(() => {
    const localValue = getLastRoundId();
    if (localValue != lastRoundId) setRoundId(lastRoundId);
  }, [lastRoundId]);

  useEffect(() => {
    if (!inStowageDetailsView) {
      removeLastRoundId();
      setLastRoundId('');
      removeLastLayerChecked();
      setLastLayerChecked('');
    }
  }, [inStowageDetailsView]);

  return <LSContext.Provider value={contextData}>{children}</LSContext.Provider>;
};
