import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { IApiError } from '../types/ApiError';

interface ErrorSnackbarOptions {
  autoThrowError?: boolean;
}

const initOptions: ErrorSnackbarOptions = { autoThrowError: false };

export function useErrorSnackbar({ autoThrowError }: ErrorSnackbarOptions = initOptions) {
  const { enqueueSnackbar } = useSnackbar();

  const tratarErro = useCallback((err?: IApiError) => {
    if (!err || !err?.messages || err?.messages?.length === 0) {
      enqueueSnackbar('Something went wrong, sorry.', {
        variant: 'error'
      });
    } else {
      err.messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 5000
        });
      });
    }

    if (autoThrowError) {
      throw err;
    }
  }, []);

  return { tratarErro };
}
