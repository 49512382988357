import { useApi } from '../shared/hooks/api';

export const useUserService = () => {
  const { put } = useApi();

  const baseApi = `api/user`;

  const consentimento = async () => {
    return put(`${baseApi}/accept`);
  };

  return {
    consentimento
  };
};
