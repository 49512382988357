import { HttpTransportType, HubConnection } from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr/dist/esm/HubConnectionBuilder';
import { LogLevel } from '@microsoft/signalr/dist/esm/ILogger';
import { PropsWithChildren, createContext, useCallback, useEffect, useRef, useState } from 'react';

interface SignalRContextData {
  getConnection: () => {
    hubConnectionStayStatus: HubConnection | undefined;
    hubConnectionEvaluationStatus: HubConnection | undefined;
    // hubConnectionSectionRoundStatus: HubConnection | undefined;
    hubConnectionHoldDetails: HubConnection | undefined;
  };
  signalRStayStatus: StayStatusSignalr | null;
  signalREvaluationStatus: EvaluationStatusSignalr | null;
  signalRSectionRoundStatus: SectionRoundStatusSignalr | null;
}

export interface StayStatusSignalr {
  stayId: string;
  executeStatus: number;
}

export interface EvaluationStatusSignalr {
  evaluationId: string;
  executeStatus: number;
}

export interface SectionRoundStatusSignalr {
  holds: holdWithSectionId[];
  sectionRoundStatus: number;
}

export interface holdWithSectionId {
  holdId: string;
  sections: string[];
  rounds: string[];
}

const SignalRContext = createContext<SignalRContextData>({} as SignalRContextData);

const SignalRProvider = ({ children }: PropsWithChildren) => {
  const [stayStatus, setStayStatus] = useState<StayStatusSignalr | null>(null);
  const [evaluationStatus, setEvaluationStatus] = useState<EvaluationStatusSignalr | null>(null);
  const [sectionRoundStatus, setSectionRoundStatus] = useState<SectionRoundStatusSignalr | null>(
    null
  );

  const apiUrl = process.env.REACT_APP_BASE_API_URL;

  const hubConnectionStayStatusRef = useRef<HubConnection>();
  const hubConnectionEvaluationStatusRef = useRef<HubConnection>();
  const hubConnectionHoldDetailsRef = useRef<HubConnection>();

  const getConnection = useCallback(() => {
    return {
      hubConnectionStayStatus: hubConnectionStayStatusRef.current,
      hubConnectionEvaluationStatus: hubConnectionEvaluationStatusRef.current,
      hubConnectionHoldDetails: hubConnectionHoldDetailsRef.current
    };
  }, []);

  // HUB CONNECTION
  useEffect(() => {
    hubConnectionStayStatusRef.current = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl(`${apiUrl}/api/stayHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.None)
      .build();

    hubConnectionEvaluationStatusRef.current = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl(`${apiUrl}/api/evaluationHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.None)
      .build();

    hubConnectionHoldDetailsRef.current = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl(`${apiUrl}/api/holdHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.None)
      .build();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HOLD DETAILS HUB
  useEffect(() => {
    if (!hubConnectionHoldDetailsRef.current) return;

    hubConnectionHoldDetailsRef.current
      .start()
      .then(function () {
        // console.log('> SignalR Connected: AtualizarHoldDetails Hub');

        hubConnectionHoldDetailsRef.current?.on('AtualizarHold', (data: any) => {
          if (!data) return console.log('No hold data.');

          const copy = data;
          setSectionRoundStatus(copy);
        });
      })
      .catch(function (err: any) {
        return console.error(err.toString());
      });

    return () => {
      hubConnectionHoldDetailsRef.current?.stop();
    };
  }, []);

  // STAY HUB
  useEffect(() => {
    if (!hubConnectionStayStatusRef.current) return;

    hubConnectionStayStatusRef.current
      .start()
      .then(function () {
        // console.log('> SignalR Connected: AtualizarStay Hub');

        hubConnectionStayStatusRef.current?.on('AtualizarStay', (data: any) => {
          if (!data) return console.log('No stay data.');

          const copy = data;
          setStayStatus(copy);
        });
      })
      .catch(function (err: any) {
        return console.error(err.toString());
      });

    return () => {
      hubConnectionStayStatusRef.current?.stop();
    };
  }, []);

  // EVALUATION HUB
  useEffect(() => {
    if (!hubConnectionEvaluationStatusRef.current) return;

    hubConnectionEvaluationStatusRef.current
      .start()
      .then(function () {
        // console.log('> SignalR Connected: AtualizarEvaluation Hub');

        hubConnectionEvaluationStatusRef.current?.on('AtualizarEvaluation', (data: any) => {
          if (!data) return console.log('No evaluation data.');

          const copy = data;
          setEvaluationStatus(copy);
        });
      })
      .catch(function (err: any) {
        return console.error(err.toString());
      });

    return () => {
      hubConnectionEvaluationStatusRef.current?.stop();
    };
  }, []);

  return (
    <SignalRContext.Provider
      value={{
        getConnection,
        signalRStayStatus: stayStatus,
        signalREvaluationStatus: evaluationStatus,
        signalRSectionRoundStatus: sectionRoundStatus
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export { SignalRContext, SignalRProvider };
