import { Box } from '@mui/material';
import { OptLoading, OptSideLayout } from '@optsol/react';
import { useEffect } from 'react';

import { LgpdDrawer } from '../../components/Drawer/LgpdDrawer';
import { FalhaUsuario } from '../../components/FalhaUsuario';
import { useAuthenticationContext } from '../../contexts/Authentication/authenticationContext';
import { appRoutes } from '../../routes';
import { useMsalService } from '../../services/authentication/msal.service';
import { Colors } from '../../shared/colors';
import { arcelorIcon, chatSquareIcon } from '../../shared/CustomIcons';

import './App.css';
import { useSections } from './App.sections';

function App() {
  const msal = useMsalService();
  const sections = useSections();
  const {
    state: { userInfo },
    inicializar: inicializarToken
  } = useAuthenticationContext();

  async function inicializar() {
    await inicializarToken();
  }

  useEffect(() => {
    inicializar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const possuiUserInfo = userInfo && userInfo.data;
  const carregando = userInfo?.loading;

  if (carregando)
    return (
      <Box
        sx={{
          display: 'flex',
          height: '90%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 5,
          position: 'absolute'
        }}
      >
        <OptLoading size={45} />
      </Box>
    );
  if (!carregando && !possuiUserInfo) return <FalhaUsuario />;

  return (
    <>
      <OptSideLayout
        logo={{ icon: arcelorIcon, iconColor: 'transparent', path: '/' }}
        sections={sections}
        routes={appRoutes}
        onLogout={msal.logout}
        onManageProfile={() => {}}
        profile={{
          name: userInfo.data?.userName ?? 'no name',
          email: userInfo.data?.userEmail ?? 'no email',
          avatarSrc: undefined
        }}
        appBarConfig={{
          sectionsAlignment: 'center',
          hideLinkDescription: true,
          actions: [
            {
              icon: chatSquareIcon,
              iconColor: Colors.primary,
              onClick: () => {},
              title: 'Notifications'
            }
          ]
        }}
        version="v1.0"
      />
      <LgpdDrawer userInfo={userInfo} />
    </>
  );
}

export default App;
