import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';

import { MsalConfig } from '../../shared/msal/config';

type Full<T> = {
  [P in keyof T]-?: T[P];
};

export function useMsalService() {
  const { accounts, instance } = useMsal();

  const getAccessToken = useCallback(
    async (scopes = MsalConfig.scopes) => {
      if (accounts.length > 0) {
        const currentAccount = accounts[0] as Full<AccountInfo>;

        const request: SilentRequest = {
          scopes: scopes,
          account: currentAccount
        };

        const accessToken = await instance
          .acquireTokenSilent(request)
          .then((response) => {
            return response.accessToken;
          })
          .catch((error) => {
            // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
            console.error(error);
            return undefined;
          });

        return accessToken;
      } else {
        throw new Error('Erro ao obter token');
      }
    },
    [accounts, instance]
  );

  async function logout() {
    instance.logoutRedirect();
  }

  return { getAccessToken, logout };
}
