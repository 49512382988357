import { Box, Drawer, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { useUserService } from '../../services/user.service';
import { UserInfo } from '../../shared/msal/UserInfo';
import { LoadableData } from '../../shared/types/LoadableData';
import { BotaoAccept } from '../Botao/BotaoAccept';

interface Props {
  userInfo: LoadableData<UserInfo | undefined>;
}

export const LgpdDrawer = ({ userInfo }: Props) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const { consentimento } = useUserService();

  const onCloseDrawer = () => {
    if (userInfo.data) {
      userInfo.data.acceptTerms && setOpenDrawer(false);
      !userInfo.data.acceptTerms && setOpenDrawer(true);
    }
  };

  const onAcceptConsent = async () => {
    const onConsent = await consentimento();
    if (onConsent.success) {
      window.location.reload();
    } else {
      console.error('Error accepting consent terms.');
    }
  };

  useEffect(() => {
    if (userInfo.data) {
      !userInfo.data.acceptTerms && setOpenDrawer(true);
    }
  }, [userInfo.data]);

  return (
    <Drawer anchor="bottom" open={openDrawer} onClose={onCloseDrawer}>
      <Box display="flex" justifyContent="center" alignItems="center" py={2} px={5}>
        <Box>
          <Typography fontWeight={500}>Consent Term:</Typography>
          <Typography>
            {`According to the General Law of Protection of Personal Data - LGPD,
            Article 5 item XII of Law 13.709 (Brazil), this document enables the free,
            informed and unequivocal manifestation, by which the
            holder/responsible party agrees with the treatment of their personal
            data for the purpose of authentication and use of this private system,
            by the Controller OPTSOL Tecnologia. User Data: name, email.`}
          </Typography>
        </Box>

        <BotaoAccept texto="Accept" onClick={onAcceptConsent} />
      </Box>
    </Drawer>
  );
};
