import { Button } from '@mui/material';
import { OptLoading } from '@optsol/react';
import { ReactNode } from 'react';

import { Colors } from '../../shared/colors';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  width?: number | string;
  disabled?: boolean;
  loading?: boolean;
  secondary?: boolean;
  sx?: any;
  children?: ReactNode;
  onlyIcon?: ReactNode;
}

export const ActionButton = ({
  onClick,
  title,
  width,
  disabled,
  loading,
  secondary,
  sx,
  children,
  onlyIcon
}: Props) => {
  return (
    <Button
      size="small"
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: !secondary ? Colors.primary : Colors.white,
        height: '32px',
        width: width ?? 'auto',
        color: !secondary ? Colors.white : Colors.gray1,
        paddingLeft: '10px',
        paddingRight: '10px',
        borderRadius: '7px',
        textTransform: 'none',
        fontFamily: 'Inter, sans-serif',
        fontSize: '0.875rem',
        whiteSpace: 'nowrap',
        border: secondary ? `solid 0.5px ${Colors.gray3}` : '',
        '&:hover': {
          border: secondary ? `solid 0.5px  ${Colors.gray2}` : '',
          backgroundColor: secondary ? 'transparent' : Colors.primaryTints.tint1
        },
        '&:disabled': {
          backgroundColor: Colors.gray7,
          color: Colors.gray3
        },
        '&:active': {
          boxShadow: 'box-shadow 7px 6px 28px 1px rgba(0, 0, 0, 0.24)',
          transform: 'scale(0.97)'
        },
        ...sx
      }}
    >
      {!onlyIcon && disabled && loading ? <OptLoading size={22} /> : title}
      {!onlyIcon && children && !loading && children}
      {onlyIcon && <>{onlyIcon}</>}
    </Button>
  );
};
