import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import React, { createContext, useCallback, useReducer } from 'react';

import { SectionsClaims } from '../../models/claims/sectionsClaims';
import { useAuthenticationService } from '../../services/authentication/authentication.service';
import { useMsalService } from '../../services/authentication/msal.service';
import { MsalConfig, pca } from '../../shared/msal/config';

import { AuthenticationActions } from './authenticationActions';
import {
  AUTHENTICATION_INITIAL_DISPATCH,
  AuthenticationDispatch,
  AuthenticationReducer
} from './authenticationReducer';
import { AUTHENTICATION_INITIAL_STATE, AuthenticationState } from './authenticationState';

const AuthenticationStateContext = createContext<AuthenticationState>(AUTHENTICATION_INITIAL_STATE);
const AuthenticationDispatchContext = createContext<AuthenticationDispatch>(
  AUTHENTICATION_INITIAL_DISPATCH
);

type AuthenticationProps = { children: React.ReactNode };

function AuthenticationProvider({ children }: AuthenticationProps) {
  const [state, dispatch] = useReducer(AuthenticationReducer, AUTHENTICATION_INITIAL_STATE);

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{ scopes: MsalConfig.scopes }}
      >
        <AuthenticationStateContext.Provider value={state}>
          <AuthenticationDispatchContext.Provider value={dispatch}>
            {children}
          </AuthenticationDispatchContext.Provider>
        </AuthenticationStateContext.Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

function useAuthenticationContext() {
  const state = React.useContext(AuthenticationStateContext);
  const authenticationService = useAuthenticationService();
  const { getAccessToken } = useMsalService();

  if (state === undefined) {
    throw new Error(
      'useAuthenticationState deve ser utilizando dentro de um AuthenticationProvider'
    );
  }

  const dispatch = React.useContext(AuthenticationDispatchContext);

  if (dispatch === undefined) {
    throw new Error(
      'useAuthenticationDispatch deve ser utilizando dentro de um AuthenticationProvider'
    );
  }

  const actions = AuthenticationActions;

  const hasAccess = useCallback(
    (access: string) => {
      if (!state.userInfo.data) return false;

      return state.userInfo.data.accesses.some((x) => x === access);
    },
    [state.userInfo.data]
  );

  const hasAccessToContent = (access: SectionsClaims) => {
    if (Array.isArray(access)) {
      return access.some((x) => hasAccess(x));
    } else {
      return hasAccess(access);
    }
  };

  async function inicializar() {
    dispatch({ type: actions.LOADING_USER_INFO, payload: true });

    try {
      const token = await getAccessToken();
      if (token) {
        definirToken(token);
        // const historyToken = await getAccessToken(MsalConfig.historyScopes);
        // definirHistoryToken(historyToken);

        await carregarUserInfo(token);
      } else {
        dispatch({ type: actions.LOADING_USER_INFO, payload: false });
      }
    } catch (err) {
      dispatch({ type: actions.LOADING_USER_INFO, payload: false });
      throw err;
    }
  }

  async function carregarUserInfo(token?: string) {
    const result = await authenticationService.getUserInfo(token, state.tenantId);

    dispatch({ type: actions.CARREGAR_USER_INFO, payload: result });
  }

  function atualizarUserInfo(fotoPerfil: string, name: string) {
    if (state.userInfo.data) {
      const userInfo = { ...state.userInfo.data };
      userInfo.fotoPerfil = fotoPerfil;
      userInfo.userName = name;
      dispatch({ type: actions.CARREGAR_USER_INFO, payload: userInfo });
    }
  }

  function definirTenant(tenantId?: string) {
    dispatch({ type: actions.DEFINIR_TENANT, payload: tenantId });
  }

  function definirToken(token?: string) {
    if (token !== state.token) dispatch({ type: actions.DEFINIR_TOKEN, payload: token });
  }

  // function definirHistoryToken(historyToken?: string) {
  //   if (historyToken !== state.historyToken)
  //     dispatch({ type: actions.DEFINIR_HISTORY_TOKEN, payload: historyToken });
  // }

  return {
    state,
    carregarUserInfo,
    hasAccess,
    hasAccessToContent,
    definirTenant,
    definirToken,
    // definirHistoryToken,
    inicializar,
    atualizarUserInfo
  };
}

export { AuthenticationProvider, useAuthenticationContext };
