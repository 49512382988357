import { mdiAccountCog, mdiAnchor, mdiCog, mdiFerry, mdiLayersTriple } from '@mdi/js';
import { OptMenuSection } from '@optsol/react';

import { useAuthenticationContext } from '../../contexts/Authentication/authenticationContext';
import { SectionsClaims } from '../../models/claims/sectionsClaims';
import { AppRoutes } from '../../routes';

export const useSections = () => {
  const { hasAccess } = useAuthenticationContext();

  const sections: OptMenuSection[] = [
    {
      title: 'Stowage',
      items: []
    }
  ];

  const protectedItems: OptMenuSection = {
    title: 'ProtectedStowage',
    items: []
  };
  if (hasAccess(SectionsClaims.VESSELS_MANAGEMENT)) {
    protectedItems.items.push({
      icon: mdiAnchor,
      path: AppRoutes.Ports.main,
      title: 'Ports'
    });
  }

  if (hasAccess(SectionsClaims.STAYS_MANAGEMENT)) {
    protectedItems.items.push({
      icon: mdiLayersTriple,
      path: AppRoutes.Stays.main,
      title: 'Stays'
    });
  }

  if (hasAccess(SectionsClaims.VESSELS_MANAGEMENT)) {
    protectedItems.items.push({
      icon: mdiFerry,
      path: AppRoutes.Vessels.main,
      title: 'Vessels'
    });
  }

  if (hasAccess(SectionsClaims.PARAMS_MANAGEMENT)) {
    protectedItems.items.push({
      icon: mdiCog,
      path: AppRoutes.Parameters.main,
      title: 'Parameters'
    });
  }

  if (hasAccess(SectionsClaims.PROFILES_MANAGEMENT)) {
    protectedItems.items.push({
      icon: mdiAccountCog,
      path: AppRoutes.Profiles.main,
      title: 'Profiles'
    });
  }

  if (protectedItems.items.length) {
    protectedItems.items.map((item) => {
      return sections[0].items.push(item);
    });
  }

  return sections;
};
