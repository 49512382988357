import { createContext, useEffect, useState } from 'react';

import { HoldsEvaluationModel } from '../../models/avaliacoes/AvaliacoesFormModel';
import { ObterEvaluationHoldsResponse } from '../../models/dtos/EstadiaSearchResponse';

interface ActiveHoldButtonModel {
  holdNumber: number;
  activeHold: boolean;
}

interface ActiveHoldsModel {
  slab: ActiveHoldButtonModel[];
  coilSheet: ActiveHoldButtonModel[];
}

export interface TestPassingModel {
  pass: boolean;
  holdNumber: number;
}

interface StaysContextType {
  stayId: string | null;
  handleChangeStayId: (newStayId: string | null) => void;
  stowagePlanId: string;
  evaluationId: string | null;
  handleChangeEvaluationId: (newEvaluationId: string | null) => void;
  handleChangeStowagePlanId: (newStowageId: string) => void;
  activeHolds: ActiveHoldsModel | null;
  handleChangeActiveHolds: (
    slab: HoldsEvaluationModel[],
    coilSheet: HoldsEvaluationModel[]
  ) => void;
  callGetLayer: { callLayer: boolean | null; plaqueId: string | null };
  callGetHolds: boolean;
  handleCallGetHolds: (newValue: boolean) => void;
  handleCallGetLayer: (callLayer: boolean | null, plaqueId: string | null) => void;
  canSeeLoaded: boolean | null;
  handleChangeCanSeeLoaded: (newValue: boolean | null) => void;
  hasPenalityAreaNotCompleted: boolean | null;
  onChangeHasPenalityAreaNotCompleted: (newValue: boolean | null) => void;
  ////
  holdAtualValue: ObterEvaluationHoldsResponse | null | undefined;
  roundAtualValue: number;
  roundOrderAtual: number;
  sectionAtualValue: number;
  changeHoldAtualValue: (newValue: ObterEvaluationHoldsResponse | null | undefined) => void;
  changeRoundAtualValue: (newValue: number) => void;
  changeSectionAtualValue: (newValue: number) => void;
  onAddRoundModify: boolean;
  changeOnAddRoundModify: (bool: boolean) => void;
}

const StaysContext = createContext<StaysContextType>({
  stayId: null,
  evaluationId: null,
  activeHolds: null,
  stowagePlanId: '',
  handleChangeStayId: (_newStayId: string | null) => {},
  handleChangeEvaluationId: (_newEvaluationId: string | null) => {},
  handleChangeStowagePlanId: (_newStowageId: string) => {},
  handleChangeActiveHolds: (
    _slab: HoldsEvaluationModel[],
    _coilSheet: HoldsEvaluationModel[]
  ) => {},
  callGetLayer: { callLayer: null, plaqueId: null },
  callGetHolds: false,
  handleCallGetHolds: (_newValue: boolean) => {},
  handleCallGetLayer: (_callLayer: boolean | null, _plaqueId: string | null) => {},
  canSeeLoaded: null,
  handleChangeCanSeeLoaded: (_newValue: boolean | null) => {},
  hasPenalityAreaNotCompleted: null,
  onChangeHasPenalityAreaNotCompleted: (_newValue: boolean | null) => {},
  ///
  holdAtualValue: null,
  roundAtualValue: 0,
  roundOrderAtual: 0,
  sectionAtualValue: 0,
  changeHoldAtualValue: (_newValue: ObterEvaluationHoldsResponse | null | undefined) => {},
  changeRoundAtualValue: (_newValue: number) => {},
  changeSectionAtualValue: (_newValue: number) => {},
  onAddRoundModify: false,
  changeOnAddRoundModify: (_bool: boolean) => {}
});

const StaysContextProvider = ({ children }: { children: any }) => {
  const [stayId, setStayId] = useState<string | null>(null);
  const [evaluationId, setEvaluationId] = useState<string | null>(null);
  const [stowagePlanId, setstowagePlanId] = useState<string>('');
  const [activeHolds, setActiveHolds] = useState<ActiveHoldsModel | null>(null);
  const [callGetHolds, setCallGetHolds] = useState(false);
  const [callGetLayer, setCallGetLayer] = useState<{
    callLayer: boolean | null;
    plaqueId: string | null;
  }>({ callLayer: null, plaqueId: null });
  const [canSeeLoaded, setCanSeeLoaded] = useState<boolean | null>(null);
  const [hasPenalityAreaNotCompleted, setHasPenalityAreaNotCompleted] = useState<boolean | null>(
    null
  );
  const [holdAtualValue, setHoldAtualValue] = useState<
    ObterEvaluationHoldsResponse | null | undefined
  >(null);
  const [roundAtualValue, setRoundAtualValue] = useState<number>(0);
  const [roundOrderAtual, setRoundOrderAtual] = useState<number>(0);
  const [sectionAtualValue, setSectionAtualValue] = useState<number>(0);
  const [onAddRoundModify, setOnAddRoundModify] = useState(false);

  const changeOnAddRoundModify = (bool: boolean) => {
    setOnAddRoundModify(bool);
  };

  const changeHoldAtualValue = (newValue: ObterEvaluationHoldsResponse | null | undefined) => {
    setHoldAtualValue(newValue);
  };

  const changeRoundAtualValue = (newValue: number) => {
    setRoundAtualValue(newValue);
  };

  const changeSectionAtualValue = (newValue: number) => {
    setSectionAtualValue(newValue);
  };

  const onChangeHasPenalityAreaNotCompleted = (newValue: boolean | null) => {
    setHasPenalityAreaNotCompleted(newValue);
  };

  const handleChangeCanSeeLoaded = (newValue: boolean | null) => {
    setCanSeeLoaded(newValue);
  };

  const handleCallGetLayer = (callLayer: boolean | null, plaqueId: string | null) => {
    setCallGetLayer({ callLayer, plaqueId });
  };

  const handleCallGetHolds = (newValue: boolean) => {
    setCallGetHolds(newValue);
  };

  const handleChangeStayId = (newStayId: string | null) => {
    setStayId(newStayId);
  };

  const handleChangeEvaluationId = (newEvaluationId: string | null) => {
    setEvaluationId(newEvaluationId);
  };

  const handleChangeStowagePlanId = (newStowageId: string) => {
    setstowagePlanId(newStowageId);
  };

  const handleChangeActiveHolds = (
    slab: HoldsEvaluationModel[],
    coilSheet: HoldsEvaluationModel[]
  ) => {
    const activeHoldsSlab: ActiveHoldButtonModel[] = [];
    const activeHoldsCoil: ActiveHoldButtonModel[] = [];

    if (slab && slab.length) {
      slab.map((item) =>
        activeHoldsSlab.push({ holdNumber: item.holdNumber, activeHold: !!item.activeHold })
      );
    }

    if (coilSheet && coilSheet.length) {
      coilSheet.map((item) =>
        activeHoldsCoil.push({ holdNumber: item.holdNumber, activeHold: !!item.activeHold })
      );
    }

    setActiveHolds({
      slab: activeHoldsSlab.sort((a, b) =>
        a.holdNumber && b.holdNumber && a.holdNumber > b.holdNumber ? -1 : 1
      ),
      coilSheet: activeHoldsCoil.sort((a, b) =>
        a.holdNumber && b.holdNumber && a.holdNumber > b.holdNumber ? -1 : 1
      )
    });
  };

  useEffect(() => {
    if (callGetLayer.callLayer) return setCallGetLayer({ callLayer: false, plaqueId: null });
  }, [callGetLayer]);

  useEffect(() => {
    if (callGetHolds) return setCallGetHolds(false);
  }, [callGetHolds]);

  useEffect(() => {
    if (holdAtualValue && holdAtualValue.rounds[roundAtualValue])
      setRoundOrderAtual(holdAtualValue?.rounds[roundAtualValue].order ?? 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundAtualValue]);

  const staysContextData = {
    stayId,
    evaluationId,
    stowagePlanId,
    activeHolds,
    handleChangeStayId,
    handleChangeStowagePlanId,
    handleChangeEvaluationId,
    handleChangeActiveHolds,
    callGetLayer,
    handleCallGetLayer,
    canSeeLoaded,
    handleChangeCanSeeLoaded,
    hasPenalityAreaNotCompleted,
    onChangeHasPenalityAreaNotCompleted,
    holdAtualValue,
    roundAtualValue,
    roundOrderAtual,
    sectionAtualValue,
    changeHoldAtualValue,
    changeRoundAtualValue,
    changeSectionAtualValue,
    onAddRoundModify,
    changeOnAddRoundModify,
    callGetHolds,
    handleCallGetHolds
  };

  return <StaysContext.Provider value={staysContextData}>{children}</StaysContext.Provider>;
};

export { StaysContext, StaysContextProvider };
