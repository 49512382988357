import { createContext, useCallback, useMemo, useState } from 'react';

import { CoilPreviewModel } from '../../components/Bobina/Porao';

interface EditLoadingCoilContextType {
  coilPreviewValues: CoilPreviewModel[] | null;
  updateCoilPreviewValues: (newData: CoilPreviewModel[]) => void;
  holdId: string | null;
  sectionId: string | null;
  updateHoldId: (newData: string) => void;
  updateSectionId: (newData: string) => void;
}

const EditLoadingCoilContext = createContext<EditLoadingCoilContextType>({
  coilPreviewValues: null,
  updateCoilPreviewValues: (_newData: CoilPreviewModel[]) => {},
  holdId: null,
  sectionId: null,
  updateHoldId: (_newData: string) => {},
  updateSectionId: (_newData: string) => {}
});

const EditLoadingCoilProvider = ({ children }: { children: any }) => {
  const [coilPreviewValues, setCoilPreviewValues] = useState<CoilPreviewModel[] | null>(null);
  const [holdId, setHoldId] = useState<string | null>(null);
  const [sectionId, setSectionId] = useState<string | null>(null);

  const updateCoilPreviewValues = useCallback((newData: CoilPreviewModel[]) => {
    setCoilPreviewValues(newData.filter((b) => b.radius));
  }, []);

  const updateHoldId = useCallback((newData: string) => {
    setHoldId(newData);
  }, []);

  const updateSectionId = useCallback((newData: string) => {
    setSectionId(newData);
  }, []);

  const editLoadingCoilData = useMemo(
    () => ({
      coilPreviewValues,
      updateCoilPreviewValues,
      holdId,
      sectionId,
      updateHoldId,
      updateSectionId
    }),
    [coilPreviewValues, holdId, sectionId, updateCoilPreviewValues, updateHoldId, updateSectionId]
  );

  return (
    <EditLoadingCoilContext.Provider value={editLoadingCoilData}>
      {children}
    </EditLoadingCoilContext.Provider>
  );
};

export { EditLoadingCoilContext, EditLoadingCoilProvider };
