import arcelorLogo from '../shared/images/Arcelor_logo_icon_webp.webp';

import { Colors } from './colors';

export const arcelorIcon = (
  <div id="arcelor-logo-div">
    <img
      src={arcelorLogo}
      alt="Arcelor Logo"
      style={{
        height: '38px',
        width: '38px'
      }}
    />
  </div>
);

export const chatSquareIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    fill="currentColor"
    className="bi bi-chat-square-dots-fill"
    viewBox="0 0 16 16"
    color={Colors.primary}
  >
    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
  </svg>
);
