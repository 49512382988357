import { createContext, useState } from 'react';

import { HoldsStowagePlanModelCoil } from '../../models/planoEstivagem/StowageFormModel';

interface EditStowageContextType {
  holdSectionsEditStowage: HoldsStowagePlanModelCoil[] | null;
  permanentHoldSectionsData: HoldsStowagePlanModelCoil[] | null;
  updateHoldSectionsEditStowage: (newData: HoldsStowagePlanModelCoil[]) => void;
  updatePermanentHoldSectionsData: (newData: HoldsStowagePlanModelCoil[]) => void;
  //
  proportionalAmuraWidthClone: number | null;
  updateProportionalAmuraWidthClone: (newData: number) => void;
}

const EditStowageContext = createContext<EditStowageContextType>({
  holdSectionsEditStowage: null,
  permanentHoldSectionsData: null,
  updateHoldSectionsEditStowage: (_newData: HoldsStowagePlanModelCoil[]) => {},
  updatePermanentHoldSectionsData: (_newData: HoldsStowagePlanModelCoil[]) => {},
  proportionalAmuraWidthClone: null,
  updateProportionalAmuraWidthClone: (_newData: number) => {}
});

const EditStowageContextProvider = ({ children }: { children: any }) => {
  const [holdSectionsEditStowage, setHoldSectionsEditStowage] = useState<
    HoldsStowagePlanModelCoil[] | null
  >(null);
  const [permanentHoldSectionsData, setPermanentHoldSectionsData] = useState<
    HoldsStowagePlanModelCoil[] | null
  >(null);
  const [proportionalAmuraWidthClone, setProportionalAmuraWidthClone] = useState<number | null>(
    null
  );

  function updateProportionalAmuraWidthClone(newData: number) {
    setProportionalAmuraWidthClone(newData);
  }

  function updateHoldSectionsEditStowage(newData: HoldsStowagePlanModelCoil[]) {
    setHoldSectionsEditStowage(newData);
  }

  function updatePermanentHoldSectionsData(newData: HoldsStowagePlanModelCoil[]) {
    setPermanentHoldSectionsData(newData);
  }

  const editStowageContextData = {
    holdSectionsEditStowage,
    permanentHoldSectionsData,
    updateHoldSectionsEditStowage,
    updatePermanentHoldSectionsData,
    proportionalAmuraWidthClone,
    updateProportionalAmuraWidthClone
  };

  return (
    <EditStowageContext.Provider value={editStowageContextData}>
      {children}
    </EditStowageContext.Provider>
  );
};

export { EditStowageContext, EditStowageContextProvider };
