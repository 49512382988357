import { OptTheme } from '@optsol/react';

import { Colors } from './colors';

export const theme: OptTheme = {
  light: {
    style: 'soft',
    primary: Colors.primary,
    primaryContrast: Colors.white,
    secondary: Colors.secondary,
    secondaryContrast: Colors.white
  }
};
