import { keyframes, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box/Box';
import { useLocation } from 'react-router-dom';

import { useMsalService } from '../services/authentication/msal.service';

import { ActionButton } from './Botao/ActionButton';

export const FalhaUsuario = () => {
  const { logout } = useMsalService();
  const { state } = useLocation();
  return (
    <Box
      flex={1}
      height="90vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <PushReleaseFromLeftAnimation id="animation-div">
        <Box pb={2.5}>
          <Typography fontSize={24}>Oops!</Typography>
          <Typography fontSize={16}>
            {state ? state : 'Falha ao recuperar as informações do usuário.'}
            <br />
            {state ? '' : 'Favor realizar o login novamente.'}
          </Typography>
        </Box>
        <ActionButton title="Retornar" onClick={logout} />
      </PushReleaseFromLeftAnimation>
    </Box>
  );
};

const keyframesConfig = keyframes`
  from { transform: translateX(-100%) };
  30% { transform: translateX(100px) };
`;

const PushReleaseFromLeftAnimation = styled('div')({
  animationDelay: '1000',
  animation: `${keyframesConfig} 1s both`
});
