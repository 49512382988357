import api, { AxiosRequestConfig } from 'axios';

import { BaseConfig } from '../../shared/baseConfig';
import { UserInfo } from '../../shared/msal/UserInfo';
import { ApiResponse } from '../../shared/types/ApiResponse';

export function useAuthenticationService() {
  const baseApi = BaseConfig.baseApiUrl;

  async function getUserInfo(token?: string, tenantId?: string) {
    const config: AxiosRequestConfig = {};
    config.headers = {};

    config.headers.Authorization = `Bearer ${token}`;

    if (tenantId) {
      config.headers[BaseConfig.TenantHeader] = tenantId;
    }

    const result = await api.get<ApiResponse<UserInfo>>(`${baseApi}/api/user/token/info`, config);

    return result.data.data;
  }

  return { getUserInfo };
}
