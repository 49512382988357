export const Colors = {
  primary: '#ff3100',

  primaryTints: {
    tint1: '#fc4317',
    tint2: '#ff542b',
    tint3: '#fc5f3a',
    tint4: '#ff704f',
    tint5: '#fc886d',
    tintDark1: '#f02e00',
    tintDark2: '#db2c02'
  },

  secondary: '#42545C',

  grayDefault: '#EAEEF4', //
  gray20p: 'rgb(234, 238, 244, .2)', //
  gray40p: 'rgb(234, 238, 244, .4)', //
  gray50p: 'rgb(234, 238, 244, .5)', //
  gray60p: 'rgba(234, 238, 244, 0.6)', //

  ciano: '#008B8B', //
  ciano1: '#22BABA',
  cianoHold: '#6ACBC6',
  cianoPort: '#bae7e5',

  lilac: '#d1ccf0',
  pink: '#eeb6d9',

  gray: '#25282D',
  gray1: '#333333',
  gray2: '#454854',
  gray3: '#757575',
  gray4: '#828282',
  gray4b: '#9c9c9c',
  gray5: '#b5b5b5',
  gray6: '#cccccc',
  gray7: '#d9d9d9',
  gray8: '#ebebeb',
  gray9: '#EAEEF4',
  gray10: '#f5f5f5',
  gray11: '#f2f2f2',
  grayHold: '#D5DAE1',

  white: '#FFFFFF',

  blue1: '#2F80ED',
  blue2: '#2D9CDB',
  blue3: '#56CCF2',
  blue4: '#004E95',
  blue5: '#4169E1',
  blue6: '#6e9ef5',
  blue7: '#90bffc',
  blue8: '#BEC7F6',
  blue9: '#e6e6f5',
  blueNavio: '#e9eef4',
  blueHold: '#6495ED',

  green1: '#7ec47e',
  green2: '#27AE60',
  green3: '#47DE87',
  green4: '#d6f5d6',
  red: '#E20000',
  red1: '#EB5757',
  red2: '#ed6d6d',
  red3: '#e88787',
  red4: '#e39494',
  red5: '#e3a6a6',
  red6: '#ffede6',
  yellow: '#F2C94C',
  yellowHold: '#FFE588',
  orange: '#fcd181',
  brown: '#d9b96f',

  running: '#FFAE4F',
  done: '#5AC55E',
  done2: '#4C8DF9',
  finished: '#2E8B57',

  area1Modify: '#BAB3E8',
  area1ModifyBase: '#E6E6FA',
  area2Modify: '#4DC1BB',
  area2ModifyBase: '#d8f0ee',
  area3Modify: '#FFE454',
  area3ModifyBase: '#fcf6d2',

  // area2: "#6acbc6", //106, 203, 198
  // area3: "#fbda61", //251, 218, 97
  //loadSection: "#eaeef4", //234, 238, 244
  area1: 'rgba(100, 149, 237, 0.8)',
  area2: 'rgba(106, 203, 198, 0.8)',
  area3: 'rgba(251, 218, 97, 0.8)',
  loadSection: 'rgba(234, 238, 244, 0.9)',
  loadSectionPonta: 'rgba(234, 238, 244, 0.6)',

  black1: '#000000'
} as const;
