import { Fragment, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

// import Home from '../pages/Home/Home';

// import { ParameterRoutes } from './Parameter.routes';
// import { PortRoutes } from './Port.routes';
// import { ProfileRoutes } from './Profile.routes';
// import { StayRoutes } from './Stay.routes';
// import { VesselRoutes } from './Vessel.routes';

export const AppRoutes = {
  _Params: {
    id: ':id'
  },
  Ports: {
    main: '/ports',
    details: (id = ':id') => `/ports/${id}`
  },
  Home: '/',
  Stays: {
    main: '/stays',
    details: (stayId = ':stayId') => `/stays/${stayId}`,
    details_string: ':stayId',
    editStay: (stayId = ':stayId') => `/stays/${stayId}/edit`,
    editStay_string: ':stayId/edit',
    evaluation: (stayId = ':stayId') => `/stays/${stayId}/evaluation`,
    evaluation_string: ':stayId/evaluation',
    evaluationDetails: (stayId = ':stayId', evaluationId = ':evaluationId') =>
      `/stays/${stayId}/evaluation/${evaluationId}`,
    evaluationDetails_string: ':stayId/evaluation/:evaluationId',
    stowagePlan: (stayId = ':stayId') => `/stays/${stayId}/stowagePlan`,
    stowagePlan_string: ':stayId/stowagePlan',
    stowagePlanDetails: (stayId = ':stayId', stowagePlanId = ':stowagePlanId') =>
      `/stays/${stayId}/stowagePlan/${stowagePlanId}`,
    stowagePlanDetails_string: ':stayId/stowagePlan/:stowagePlanId',
    stowagePlanDetailsWithQuery: (
      stayId = ':stayId',
      stowagePlanId = ':stowagePlanId',
      holdNumber = ':holdNumber'
    ) => `/stays/${stayId}/stowagePlan/${stowagePlanId}?hold=${holdNumber}`,
    stowagePlanDetailsWithQuery_string: ':stayId/stowagePlan/:stowagePlanId?hold=:holdNumber',
    stowagePlanEdit: (
      stayId = ':stayId',
      stowagePlanId = ':stowagePlanId',
      roundId = ':roundId',
      holdId: string
    ) => `/stays/${stayId}/stowagePlan/${stowagePlanId}/round/${roundId}/hold/${holdId}`,
    stowagePlanEdit_string: ':stayId/stowagePlan/:stowagePlanId/round/:roundId/hold/:holdId',
    stowagePlanEditCoil: (
      stayId = ':stayId',
      stowagePlanId = ':stowagePlanId',
      roundId = ':roundId',
      holdId: string
    ) => `/stays/${stayId}/stowagePlan/${stowagePlanId}/round/${roundId}/hold/${holdId}/modify `,
    stowagePlanEditCoil_string:
      ':stayId/stowagePlan/:stowagePlanId/round/:roundId/hold/:holdId/modify '
  },
  Vessels: {
    main: '/vessels',
    details: (id = ':id') => `/vessels/${id}`
  },
  Parameters: {
    main: '/parameters'
  },
  Profiles: {
    main: '/profiles',
    details: (id = ':id') => `/profiles/${id}`
  }
} as const;

const Home = lazy(() => import('../pages/Home/Home'));
const PortRoutes = lazy(() => import('./Port.routes').then((m) => ({ default: m.PortRoutes })));
const StayRoutes = lazy(() => import('./Stay.routes').then((m) => ({ default: m.StayRoutes })));
const VesselRoutes = lazy(() =>
  import('./Vessel.routes').then((m) => ({ default: m.VesselRoutes }))
);
const ParameterRoutes = lazy(() =>
  import('./Parameter.routes').then((m) => ({ default: m.ParameterRoutes }))
);
const ProfileRoutes = lazy(() =>
  import('./Profile.routes').then((m) => ({ default: m.ProfileRoutes }))
);

export const appRoutes = () => {
  return (
    <Suspense fallback={<Fragment></Fragment>}>
      <Routes>
        <Route path={AppRoutes.Home} element={<Home />} />
        <Route path={`${AppRoutes.Ports.main}/*`} element={<PortRoutes />} />
        <Route path={`${AppRoutes.Stays.main}/*`} element={<StayRoutes />} />
        <Route path={`${AppRoutes.Vessels.main}/*`} element={<VesselRoutes />} />
        <Route path={`${AppRoutes.Parameters.main}/*`} element={<ParameterRoutes />} />
        <Route path={`${AppRoutes.Profiles.main}/*`} element={<ProfileRoutes />} />
      </Routes>
    </Suspense>
  );
};
