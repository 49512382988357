import { createContext, useEffect, useState } from 'react';

import { SectionDetailsModel } from '../../components-indenpendant/ExpandableLayer/SectionData';
import { CoilsLineHeight } from '../../models/coils/CoilPreview';
import {
  COILS_ON_HEIGHTS_RESPONSE_EMPTY,
  CoilHeightsResponse
} from '../../models/dtos/CoilHeightsResponse';

interface MaxValueByGroupModel {
  A: { total: number; used: number };
  B: { total: number; used: number };
  C: { total: number; used: number };
  D: { total: number; used: number };
  E: { total: number; used: number };
  F: { total: number; used: number };
  G: { total: number; used: number };
  H: { total: number; used: number };
  I: { total: number; used: number };
  J: { total: number; used: number };
  K: { total: number; used: number };
  L: { total: number; used: number };
}

const MaxValuesInit: MaxValueByGroupModel = {
  A: { total: 0, used: 0 },
  B: { total: 0, used: 0 },
  C: { total: 0, used: 0 },
  D: { total: 0, used: 0 },
  E: { total: 0, used: 0 },
  F: { total: 0, used: 0 },
  G: { total: 0, used: 0 },
  H: { total: 0, used: 0 },
  I: { total: 0, used: 0 },
  J: { total: 0, used: 0 },
  K: { total: 0, used: 0 },
  L: { total: 0, used: 0 }
};

interface EditCoilHeightsContextType {
  availableCoilHeights: CoilsLineHeight[] | null;
  coilHeightsSeparated: CoilHeightsResponse | null;
  maxValueByGroup: MaxValueByGroupModel | null;
  stayExecuteStatus: number | null;
  rowSectionId: string | null;
  updateRowSectionId: (newData: string) => void;
  updateCoilHeightsSeparated: (newData: CoilHeightsResponse) => void;
  updateStayExecuteStatus: (newData: number) => void;
  updateCoilHeightsSeparatedByHeightNumber: (
    heightNumber: number,
    coilsHeightArray: CoilsLineHeight[]
  ) => void;
  updateAvailableCoilHeights: (newData: CoilsLineHeight[]) => void;
  updateMaxValueByGroup: (
    availableHeightsData: CoilsLineHeight[],
    coilHeightsData: CoilHeightsResponse
  ) => void;
  holdDetailsAtual: SectionDetailsModel | null;
  updateHoldDetailsAtual: (newData: SectionDetailsModel) => void;
}

const EditCoilHeightsContext = createContext<EditCoilHeightsContextType>({
  rowSectionId: null,
  updateRowSectionId: (_newData: string) => {},
  availableCoilHeights: null,
  coilHeightsSeparated: null,
  maxValueByGroup: null,
  stayExecuteStatus: null,
  updateCoilHeightsSeparated: (_newData: CoilHeightsResponse) => {},
  updateStayExecuteStatus: (_newData: number) => {},
  updateCoilHeightsSeparatedByHeightNumber: (
    _heightNumber: number,
    _coilsHeightArray: CoilsLineHeight[]
  ) => {},
  updateAvailableCoilHeights: (_newData: CoilsLineHeight[]) => {},
  updateMaxValueByGroup: (
    _availableHeightsData: CoilsLineHeight[],
    _coilHeightsData: CoilHeightsResponse
  ) => {},
  holdDetailsAtual: null,
  updateHoldDetailsAtual: (_newData: SectionDetailsModel) => {}
});

const EditCoilHeightsContextProvider = ({ children }: { children: any }) => {
  const [availableCoilHeights, setAvailableCoilHeights] = useState<CoilsLineHeight[] | null>(null);
  const [coilHeightsSeparated, setCoilHeightsSeparated] = useState<CoilHeightsResponse | null>(
    null
  );
  const [maxValueByGroup, setMaxValueByGroup] = useState<MaxValueByGroupModel | null>(null);
  const [stayExecuteStatus, setStayExecuteStatus] = useState<number | null>(null);
  const [rowSectionId, setRowSectionId] = useState<string | null>(null);

  const [holdDetailsAtual, setHoldDetailsAtual] = useState<SectionDetailsModel | null>(null);

  function updateRowSectionId(newData: string) {
    setRowSectionId(newData);
  }

  function updateCoilHeightsSeparated(newData: CoilHeightsResponse) {
    setCoilHeightsSeparated(newData);
  }

  function updateAvailableCoilHeights(newData: CoilsLineHeight[]) {
    setAvailableCoilHeights(newData);
  }

  function updateStayExecuteStatus(newData: number) {
    setStayExecuteStatus(newData);
  }

  function updateHoldDetailsAtual(newData: SectionDetailsModel) {
    setHoldDetailsAtual(newData);
  }

  function updateCoilHeightsSeparatedByHeightNumber(
    heightNumber: number,
    coilsHeightArray: CoilsLineHeight[]
  ) {
    let clone = COILS_ON_HEIGHTS_RESPONSE_EMPTY;
    if (coilHeightsSeparated) {
      clone = { ...coilHeightsSeparated };
    }

    heightNumber === 1 &&
      setCoilHeightsSeparated({ ...clone, dropedItensHeigthsOne: coilsHeightArray });
    heightNumber === 2 &&
      setCoilHeightsSeparated({
        ...clone,
        dropedItensHeigthsTwo: coilsHeightArray
      });
    heightNumber === 3 &&
      setCoilHeightsSeparated({
        ...clone,
        dropedItensHeigthsThree: coilsHeightArray
      });
    heightNumber === 4 &&
      setCoilHeightsSeparated({ ...clone, dropedItensHeigthsFour: coilsHeightArray });
  }

  function updateMaxValueByGroup(
    availableHeightsData: CoilsLineHeight[],
    coilHeightsData: CoilHeightsResponse
  ) {
    const clone = { ...MaxValuesInit };

    // Soma os countPieces de cada group do array de disponiveis
    availableHeightsData.forEach((item) => {
      const { group, countPieces } = item;
      clone[group as keyof MaxValueByGroupModel] = {
        total: clone[group as keyof MaxValueByGroupModel].total + countPieces,
        used: 0
      };
    });

    Object.values(coilHeightsData).forEach((dropedItensHeigths) => {
      dropedItensHeigths.forEach((coil: any) => {
        // Obtém a propriedade group e countPieces do objeto coil
        const { group, countPieces } = coil;

        // Soma countPieces à propriedade correspondente em MaxValues
        if (group in MaxValuesInit) {
          clone[group as keyof MaxValueByGroupModel] = {
            total: clone[group as keyof MaxValueByGroupModel].total + countPieces,
            used: clone[group as keyof MaxValueByGroupModel].used + countPieces
          };
        }
      });
    });

    setMaxValueByGroup(clone);
  }

  useEffect(() => {
    if (availableCoilHeights && coilHeightsSeparated && maxValueByGroup) {
      const clone = { ...maxValueByGroup };

      // Soma os countPieces de cada group do array de disponiveis
      availableCoilHeights.forEach((item) => {
        const { group } = item;
        clone[group as keyof MaxValueByGroupModel] = {
          total: clone[group as keyof MaxValueByGroupModel].total,
          used: 0
        };
      });

      Object.values(coilHeightsSeparated).forEach((dropedItensHeigths) => {
        dropedItensHeigths.forEach((coil: any) => {
          // Obtém a propriedade group e countPieces do objeto coil
          const { group, countPieces } = coil;

          // Soma countPieces à propriedade correspondente em MaxValues
          if (group in MaxValuesInit) {
            clone[group as keyof MaxValueByGroupModel] = {
              total: clone[group as keyof MaxValueByGroupModel].total,
              used: clone[group as keyof MaxValueByGroupModel].used + countPieces
            };
          }
        });
      });

      setMaxValueByGroup(clone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCoilHeights, coilHeightsSeparated]);

  const staysContextData = {
    availableCoilHeights,
    coilHeightsSeparated,
    maxValueByGroup,
    stayExecuteStatus,
    rowSectionId,
    updateRowSectionId,
    updateCoilHeightsSeparated,
    updateStayExecuteStatus,
    updateCoilHeightsSeparatedByHeightNumber,
    updateAvailableCoilHeights,
    updateMaxValueByGroup,
    holdDetailsAtual,
    updateHoldDetailsAtual
  };

  return (
    <EditCoilHeightsContext.Provider value={staysContextData}>
      {children}
    </EditCoilHeightsContext.Provider>
  );
};

export { EditCoilHeightsContext, EditCoilHeightsContextProvider };
