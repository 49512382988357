import { createContext, useState } from 'react';

interface RerunContextType {
  roundList: string[];
  updateRoundList: (newData: string[]) => void;
}

const RerunContext = createContext<RerunContextType>({
  roundList: [],
  updateRoundList: (_newData: string[]) => {}
});

const RerunContextProvider = ({ children }: { children: any }) => {
  const [roundList, setRoundList] = useState<string[]>([]);

  function updateRoundList(newData: string[]) {
    const data = newData.filter((item, index) => newData.indexOf(item) === index);
    setRoundList(data);
  }

  const rerunContextData = {
    roundList,
    updateRoundList
  };

  return <RerunContext.Provider value={rerunContextData}>{children}</RerunContext.Provider>;
};

export { RerunContext, RerunContextProvider };
