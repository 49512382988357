import axios, { AxiosError, AxiosInstance } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useRef } from 'react';

import { IApiError } from '../../@types/ApiError';
import { useMsalService } from '../../services/authentication/msal.service';
import { BaseConfig } from '../baseConfig';

import { useErrorSnackbar } from './errorSnackbar';

export const useAxios = (url = BaseConfig.baseApiUrl) => {
  const { getAccessToken } = useMsalService();
  const { tratarErro } = useErrorSnackbar();
  const { enqueueSnackbar } = useSnackbar();

  const initAxios = useCallback(() => {
    const api = axios.create({
      baseURL: url,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });

    api.interceptors.request.use(
      async (config) => {
        const token = await getAccessToken();
        if (token)
          config = {
            ...config,
            headers: Object.assign({ Authorization: `Bearer ${token}` }, config.headers)
          };
        return Promise.resolve(config);
      },
      (error) => {
        if (error.response.status === 403)
          enqueueSnackbar(error + ': Forbidden', { variant: 'error' });
        return Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      (axiosResponse) => {
        const responseInvalida = !axiosResponse.data;

        if (responseInvalida) {
          console.info('Controller não retornou IActionResult!');
        }

        return axiosResponse.data;
      },
      (error: AxiosError<IApiError>) => {
        tratarErro(error.response?.data);
        return Promise.reject(error.response?.data);
      }
    );

    return api;
  }, [enqueueSnackbar, getAccessToken, tratarErro, url]);

  const axiosInstance = useRef<AxiosInstance>(useMemo(() => initAxios(), [initAxios]));

  return axiosInstance.current;
};
