import { Button } from '@mui/material';
import React from 'react';

import { Colors } from '../../shared/colors';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  texto?: string;
  width?: number | string;
}

export const BotaoAccept = ({ texto, width, onClick }: Props) => {
  return (
    <Button
      size="large"
      onClick={onClick}
      sx={{
        backgroundColor: Colors.primary,
        height: '32px',
        width: width ?? 'auto',
        minWidth: '92px',
        color: Colors.white,
        marginLeft: '20px',
        paddingLeft: '13px',
        paddingRight: '13px',
        borderRadius: '7px',
        textTransform: 'capitalize',
        fontSize: 'clamp(12px, 1.3vw, 14px)',
        fontFamily: 'Inter, sans-serif',
        '&:hover': {
          backgroundColor: Colors.primaryTints.tint1
        },
        '&:active': {
          boxShadow: 'box-shadow 7px 6px 28px 1px rgba(0, 0, 0, 0.24)',
          transform: 'scale(0.97)'
        }
      }}
    >
      {texto}
    </Button>
  );
};
