import { CoilsLineHeight } from '../coils/CoilPreview';

export interface AvailableHeightsResponse {
  dragDropItens: CoilsLineHeight[];
}

export interface CoilHeightsResponse {
  dropedItensHeigthsOne: CoilsLineHeight[];
  dropedItensHeigthsTwo: CoilsLineHeight[];
  dropedItensHeigthsThree: CoilsLineHeight[];
  dropedItensHeigthsFour: CoilsLineHeight[];
  isNewRow?: boolean;
  isFirstOrLast?: boolean;
}

export const COILS_ON_HEIGHTS_RESPONSE_EMPTY: CoilHeightsResponse = {
  dropedItensHeigthsOne: [],
  dropedItensHeigthsTwo: [],
  dropedItensHeigthsThree: [],
  dropedItensHeigthsFour: []
};
