import { OptGridResponse, OptSearchResponse } from '@optsol/react';
import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

import { ApiResponse } from '../types/ApiResponse';
import { SearchRequest } from '../types/SearchRequest';

import { useAxios } from './axios';

export type UseApiOptions = {
  _semToken?: boolean;
  _external?: boolean;
};

export function useApi({ _semToken, _external }: Partial<UseApiOptions> = {}) {
  // const { enqueueSnackbar } = useSnackbar();
  // const { getAccessToken } = useMsalService();
  // const { tratarErro } = useErrorSnackbar();
  // const { state, definirToken } = useAuthenticationContext();

  // const api = axios.create({
  //   baseURL: external ? '' : BaseConfig.baseApiUrl,
  //   headers: {
  //     'Access-Control-Allow-Origin': '*'
  //   }
  // });

  const api = useAxios();

  // api.interceptors.request.use(
  //   async (config) => {
  //     const configValida = config !== undefined && config.url !== undefined;

  //     if (configValida) {
  //       try {
  //         const deveObterToken = !semToken;

  //         if (deveObterToken) {
  //           const token = await getAccessToken();
  //           definirToken(token);

  //           if (token && config.headers !== undefined) {
  //             config.headers.Authorization = `Bearer ${token}`;

  //             if (state.tenantId) {
  //               config.headers[BaseConfig.TenantHeader] = state.tenantId;
  //             }

  //             // se for necessário verificar expiração do token, considere o fato dele estar em UNIX DATE
  //             // const tokenValido = token !== undefined && token.accessToken !== undefined;
  //             // if (tokenValido) {
  //             //   config.headers['Authorization'] = `Bearer ${token.accessToken}`;
  //             // }
  //           }
  //         }
  //       } catch (e) {
  //         console.error(e);
  //       }
  //     }

  //     return config;
  //   },
  //   (error) => {
  //     if (error.response.status === 403) {
  //       enqueueSnackbar(error + ': Forbidden', { variant: 'error' });
  //     }

  //     return Promise.reject(error);
  //   }
  // );

  // api.interceptors.response.use(
  //   (axiosResponse) => {
  //     const responseInvalida = !axiosResponse.data;

  //     if (responseInvalida) {
  //       console.info('Controller não retornou IActionResult!');
  //     }

  //     return axiosResponse.data;
  //   },
  //   (error: AxiosError<IApiError>) => {
  //     tratarErro(error.response?.data);
  //     return Promise.reject(error.response?.data);
  //   }
  // );

  function gridSearch<T extends object>(
    url: string,
    data: SearchRequest<any>,
    config?: AxiosRequestConfig
  ) {
    data.page = data.page + 1;

    return api.post<T, OptSearchResponse<T>>(url, data, config).then((response) => {
      const r: OptGridResponse<T> = {
        data: response.data,
        page: response.page - 1,
        totalCount: response.total
      };

      return r;
    });
  }

  function search<T, R = OptSearchResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return api.post<T, R>(url, data, config);
  }

  function post<T, R = ApiResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return api.post<T, R>(url, data, config);
  }

  function put<T, R = ApiResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return api.put<T, R>(url, data, config);
  }

  const get = useCallback(
    <T, R = ApiResponse<T>>(url: string, config?: AxiosRequestConfig) => {
      return api.get<T, R>(url, config);
    },
    [api]
  );

  function remove<T, R = ApiResponse<T>>(url: string, config?: AxiosRequestConfig) {
    return api.delete<T, R>(url, config);
  }

  function getFile<T, R = T>(url: string, config?: AxiosRequestConfig) {
    return api.get<T, R>(url, config);
  }

  return { search, gridSearch, post, put, get, getFile, remove };
}
